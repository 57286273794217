import React from 'react';
import PropTypes from 'prop-types';

import { STANDARDS_PATH } from 'dpl/shared/constants/urls';
import Icon from 'dpl/common/components/Icon';

const GOOD_DOG_STANDARDS = [
  'Responsible breeding practices',
  'Health of breeding dogs and puppies',
  'Puppy environment and enrichment',
  'Buyer education and policies'
];

export default function GoodDogStandards({ breederName, programName }) {
  return (
    <div className="GoodDogStandards pv4 pv6-sm ph4 ph6-sm br3 bg-lightest-purple dark-gray">
      <div className="flex items-center justify-between mb2">
        <p className="fw-medium">{breederName} is a trusted Good Dog Breeder</p>
        <Icon
          name="paw-badge"
          className="stone-700"
          height="32px"
          width="32px"
        />
      </div>
      <p>
        {programName} meets or exceeds our{' '}
        <a
          href={STANDARDS_PATH}
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          community standards
        </a>{' '}
        in these areas:
      </p>
      <ul>
        {GOOD_DOG_STANDARDS.map((standard, idx) => (
          <li key={idx} className="flex items-center mt2">
            <Icon
              name="checkmark"
              className="stone-700 mr2"
              height="20px"
              width="20px"
            />
            <span>{standard}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

GoodDogStandards.propTypes = {
  breederName: PropTypes.string.isRequired,
  programName: PropTypes.string.isRequired
};
